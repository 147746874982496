import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import { MutationFetchFunction } from 'common/api/fetch';
import { ApiError, MessageResult } from 'common/api/model';
import { queryClient } from 'common/api/query-client';

import {
  SimActivationDateInput,
  SimIdInput,
  SimRefreshModel,
  SimShareLinkModel,
  SimTransferRequestInput,
  TransferSimInput,
  UpdateLabelSimInput,
  UpdateSkinSimInput,
} from './model';
import { invalidateEsimData, simKey } from './query';

const ENDPOINT = 'sims';
const TRANSFER_REQUEST_ENDPOINT = (simId: string) =>
  `${ENDPOINT}/${simId}/transfer-requests`;

function removeSimDetail(simId: string) {
  queryClient.removeQueries(
    simKey.detail({
      simId,
    }),
  );
  queryClient.removeQueries(
    simKey.pending({
      id: simId,
    }),
  );
  queryClient.removeQueries(
    simKey.history({
      id: simId,
    }),
  );
  queryClient.removeQueries(
    simKey.credential({
      simId,
    }),
  );
}

export function useUpdateLabelSim(
  options?: UseMutationOptions<MessageResult, ApiError, UpdateLabelSimInput>,
): UseMutationResult<MessageResult, ApiError, UpdateLabelSimInput> {
  return useMutation<MessageResult, ApiError, UpdateLabelSimInput>(
    async function (body) {
      return await MutationFetchFunction({
        url: `${ENDPOINT}/${body?.simId}/update-label`,
        method: 'PATCH',
        body: body?.body,
        classType: MessageResult,
      });
    },
    {
      onSuccess(data, variables, context) {
        invalidateEsimData(variables.simId);
      },
      ...options,
    },
  );
}

export function useUpdateSkinSim(
  options?: UseMutationOptions<MessageResult, ApiError, UpdateSkinSimInput>,
): UseMutationResult<MessageResult, ApiError, UpdateSkinSimInput> {
  return useMutation<MessageResult, ApiError, UpdateSkinSimInput>(
    async function (body) {
      return await MutationFetchFunction({
        url: `${ENDPOINT}/${body?.simId}/update-skin`,
        method: 'PATCH',
        body: body?.body,
        classType: MessageResult,
      });
    },
    {
      onSuccess(data, variables, context) {
        invalidateEsimData(variables.simId);
      },
      ...options,
    },
  );
}

export function useArchiveSim(
  options?: UseMutationOptions<MessageResult, ApiError, SimIdInput>,
): UseMutationResult<MessageResult, ApiError, SimIdInput> {
  return useMutation<MessageResult, ApiError, SimIdInput>(
    async function (body) {
      return await MutationFetchFunction({
        url: `${ENDPOINT}/${body?.simId}/archive`,
        method: 'PATCH',
        classType: MessageResult,
      });
    },
    {
      onSuccess(data, variables, context) {
        invalidateEsimData(variables.simId);
      },
      ...options,
    },
  );
}

export function useRestoreSim(
  options?: UseMutationOptions<MessageResult, ApiError, SimIdInput>,
): UseMutationResult<MessageResult, ApiError, SimIdInput> {
  return useMutation<MessageResult, ApiError, SimIdInput>(
    async function (body) {
      return await MutationFetchFunction({
        url: `${ENDPOINT}/${body?.simId}/restore`,
        method: 'PATCH',
        classType: MessageResult,
      });
    },
    {
      onSuccess(data, variables, context) {
        invalidateEsimData(variables.simId);
      },
      ...options,
    },
  );
}

export function useDeleteSim(
  options?: UseMutationOptions<MessageResult, ApiError, SimIdInput>,
): UseMutationResult<MessageResult, ApiError, SimIdInput> {
  return useMutation<MessageResult, ApiError, SimIdInput>(
    async function (body) {
      return await MutationFetchFunction({
        url: `${ENDPOINT}/${body?.simId}`,
        method: 'DELETE',
        classType: MessageResult,
      });
    },
    {
      onSuccess(data, variables, context) {
        invalidateEsimData(variables.simId);
        removeSimDetail(variables.simId);
      },
      ...options,
    },
  );
}

export function useShareLinkSim(
  options?: UseMutationOptions<SimShareLinkModel, ApiError, SimIdInput>,
): UseMutationResult<SimShareLinkModel, ApiError, SimIdInput> {
  return useMutation<SimShareLinkModel, ApiError, SimIdInput>(async function (
    body,
  ) {
    return await MutationFetchFunction({
      url: `${ENDPOINT}/${body?.simId}/share-link`,
      method: 'POST',
      classType: SimShareLinkModel,
    });
  }, options);
}

export function useTransferSim(
  options?: UseMutationOptions<MessageResult, ApiError, TransferSimInput>,
): UseMutationResult<MessageResult, ApiError, TransferSimInput> {
  return useMutation<MessageResult, ApiError, TransferSimInput>(
    async function (body) {
      return await MutationFetchFunction({
        url: `${ENDPOINT}/${body?.simId}/transfer-sim`,
        method: 'POST',
        body: body?.body,
      });
    },
    {
      onSuccess(data, variables, context) {
        invalidateEsimData(variables.simId);
        removeSimDetail(variables.simId);
      },
      ...options,
    },
  );
}

export function useCreateTransferRequest(
  options?: UseMutationOptions<MessageResult, ApiError, SimIdInput>,
): UseMutationResult<MessageResult, ApiError, SimIdInput> {
  return useMutation<MessageResult, ApiError, SimIdInput>(
    async function (body) {
      return await MutationFetchFunction({
        url: `${ENDPOINT}/${body?.simId}/transfer-requests`,
        method: 'POST',
      });
    },
    {
      onSuccess(data, variables, context) {
        invalidateEsimData(variables.simId);
      },
      ...options,
    },
  );
}

export function useRefreshSim(
  options?: UseMutationOptions<SimRefreshModel, ApiError, SimIdInput>,
): UseMutationResult<SimRefreshModel, ApiError, SimIdInput> {
  return useMutation<SimRefreshModel, ApiError, SimIdInput>(async function (
    body,
  ) {
    return await MutationFetchFunction({
      url: `${ENDPOINT}/${body?.simId}/refresh`,
      method: 'POST',
    });
  }, options);
}

export function useAcceptTransferRequest(
  options?: UseMutationOptions<
    MessageResult,
    ApiError,
    SimTransferRequestInput
  >,
): UseMutationResult<MessageResult, ApiError, SimTransferRequestInput> {
  return useMutation<MessageResult, ApiError, SimTransferRequestInput>(
    async function (body) {
      return await MutationFetchFunction({
        url: `${TRANSFER_REQUEST_ENDPOINT(body.simId)}/${body.simTransferRequestId}/accept`,
        method: 'PATCH',
      });
    },
    {
      onSuccess(data, variables, context) {
        invalidateEsimData(variables.simId);
        removeSimDetail(variables.simId);
      },
      ...options,
    },
  );
}

export function useRejectTransferRequest(
  options?: UseMutationOptions<
    MessageResult,
    ApiError,
    SimTransferRequestInput
  >,
): UseMutationResult<MessageResult, ApiError, SimTransferRequestInput> {
  return useMutation<MessageResult, ApiError, SimTransferRequestInput>(
    async function (body) {
      return await MutationFetchFunction({
        url: `${TRANSFER_REQUEST_ENDPOINT(body.simId)}/${body.simTransferRequestId}/reject`,
        method: 'PATCH',
      });
    },
    {
      onSuccess(data, variables, context) {
        invalidateEsimData(variables.simId);
      },
      ...options,
    },
  );
}

export function useUpdateSimActivationDate(
  options?: UseMutationOptions<MessageResult, ApiError, SimActivationDateInput>,
): UseMutationResult<MessageResult, ApiError, SimActivationDateInput> {
  return useMutation<MessageResult, ApiError, SimActivationDateInput>(
    async function (body) {
      return await MutationFetchFunction({
        url: `${ENDPOINT}/${body?.simId}/sim-plans/${body?.simPlan}/update-activation-date`,
        method: 'PATCH',
        body: body?.body,
      });
    },
    {
      onSuccess(data, variables, context) {
        invalidateEsimData(variables.simId);
      },
      ...options,
    },
  );
}
