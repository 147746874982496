import notification from 'common/helpers/notification';
import useNativeBridge from 'common/routes/bridge';
import { BridgeMessageType } from 'common/routes/bridge-types';
import Copy from 'copy-to-clipboard';
import { useTranslation } from 'react-i18next';

import useDetectDevice from './use-detect-device';

export default function useClipboard() {
  const { t } = useTranslation();
  const { isKurosimApp } = useDetectDevice();
  const send = useNativeBridge({});
  return {
    async copy(value: string) {
      if (isKurosimApp) {
        send?.({ data: value, type: BridgeMessageType.CopyclipboardRequest });
        notification.success({ message: t('error:copy_success') });
        return;
      }
      try {
        await Copy(value);
        notification.success({ message: t('error:copy_success') });
      } catch (err) {
        console.error(err);
        notification.error({ message: t('error:copy_failed') });
      }
    },
  };
}
