import { UseMutateAsyncFunction } from '@tanstack/react-query';
import { SimIdInput, SimShareLinkModel } from 'api-hooks/sim/model';
import { ApiError } from 'common/api/model';
import notification from 'common/helpers/notification';
import Text from 'components/elements/text';
import TextInput from 'components/elements/text-input';
import Alert from 'components/widgets/alert';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import structuralStyles from 'styles/layout.css';

interface Props {
  simId?: string;
  link: string;
  mutateAsync: UseMutateAsyncFunction<
    SimShareLinkModel,
    ApiError,
    SimIdInput,
    unknown
  >;
}

export default function TransferLink(props: Props) {
  const { simId, link, mutateAsync } = props;
  const { t } = useTranslation();

  React.useEffect(() => {
    try {
      mutateAsync({ simId: simId! });
    } catch (e: any) {
      console.error(e);

      notification.error({ message: e.message });
    }
  }, [mutateAsync, simId]);

  return (
    <div
      className={structuralStyles.flexbox({
        direction: 'column',
        align: 'stretch',
        gap: 16,
      })}
    >
      <Text>{t('sim:transfer_link_desc')}</Text>
      <Alert alertVariant="warn">{t('sim:transfer_account_desc')}</Alert>
      <TextInput disabled value={link} />
    </div>
  );
}
