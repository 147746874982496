import { ActionIcon } from '@mantine/core';
import { useLocalStorage } from '@mantine/hooks';
import { DotsThreeCircle, Eye, EyeSlash, Globe } from '@phosphor-icons/react';
import { SimModel } from 'api-hooks/sim/model';
import classNames from 'classnames';
import { CopyIcon } from 'common/assets';
import { AssetPaths } from 'common/constants/assets';
import { LocalStorageKeys } from 'common/constants/browser-storage-keys';
import { SimSkinEnum, SimStatusEnum } from 'common/constants/enum';
import colors from 'common/styles/colors';
import Tooltip, { interceptClickEvents } from 'components/common/tooltip';
import Text from 'components/elements/text';
import { BottomSheetRemote } from 'components/widgets/bottom-sheet';
import useClipboard from 'hooks/use-clipboard';
import chunk from 'lodash/chunk';
import ESimActions from 'modules/esim/components/actions';
import React from 'react';
import { useTranslation } from 'react-i18next';
import structuralStyles from 'styles/layout.css';

import { EsimCardStyles } from './styles.css';

// +=====================+
// |   CENSORED STATE    |
// +=====================+

function useEsimCensored() {
  const [censored, setCensored] = useLocalStorage<boolean>({
    key: LocalStorageKeys.EsimCensored,
    defaultValue: false,
    serialize: JSON.stringify,
    deserialize: JSON.parse,
  });

  return {
    censored,
    setCensored,
    censorName(name: string) {
      if (!censored) {
        return name;
      }
      const nameSections = name.split(' ');
      const censoredNameSections = nameSections.map(
        (section) => section[0] + '*'.repeat(section.length - 1),
      );
      return censoredNameSections.join(' ');
    },
    censorIccid(iccid: string) {
      const iccidSections = chunk(iccid.split(''), 4);
      if (!censored) {
        return iccidSections.map((row) => row.join('')).join(' ');
      }

      return iccidSections
        .map((section, idx, arr) => {
          if (idx === arr.length - 1) {
            return section.join('');
          } else {
            return '*'.repeat(section.length);
          }
        })
        .join(' ');
    },
  };
}

// +=====================+
// |       STATUS        |
// +=====================+

interface EsimCardStatusIndicatorProps {
  status: SimStatusEnum;
}

export function EsimCardStatusIndicator(props: EsimCardStatusIndicatorProps) {
  const { status } = props;
  const { t } = useTranslation();

  let color: string, message: string, tooltip: string;
  switch (status) {
    case SimStatusEnum.Activated:
      color = colors.sentimentPositive;
      message = t('common:active');
      tooltip = t('sim:status.active.tooltip');
      break;
    case SimStatusEnum.Installed:
      color = colors.yellowColor;
      message = t('sim:installed');
      tooltip = t('sim:status.installed.tooltip');
      break;
    case SimStatusEnum.Assigned:
      color = colors.foregroundTertiary;
      message = t('common:not_extra', { extra: t('sim:installed') });
      tooltip = t('sim:status.not_installed.tooltip');
      break;
    case SimStatusEnum.Deactivated:
      color = colors.sentimentNegative;
      message = t('common:inactive');
      tooltip = t('sim:status.inactive.tooltip');
      break;
    default:
      return null;
  }
  return (
    <Tooltip label={tooltip}>
      <div
        className={structuralStyles.flexbox({ gap: 4 })}
        onClick={interceptClickEvents}
      >
        <div
          className={EsimCardStyles.statusNode}
          style={{
            backgroundColor: color,
          }}
        />
        <Text textColor="mainWhite">{message}</Text>
      </div>
    </Tooltip>
  );
}

// +=====================+
// |       ACTIONS       |
// +=====================+

interface EsimActionsButtonProps {
  data: SimModel | undefined;
}

export function EsimActionsButton(props: EsimActionsButtonProps) {
  const remote = React.useRef<BottomSheetRemote | null>(null);
  return (
    <>
      <ActionIcon
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          remote.current?.open();
        }}
        variant="transparent"
      >
        <DotsThreeCircle size={24} color={colors.mainWhite} />
      </ActionIcon>
      {props.data && <ESimActions ref={remote} data={props.data} />}
    </>
  );
}

// +=====================+
// |        MAIN         |
// +=====================+

interface EsimCardProps {
  label: string | undefined;
  iccid: string | undefined;
  skin: SimSkinEnum | undefined;
  status: SimStatusEnum | undefined;

  onClick?(): void;

  Actions?: React.ReactNode;
}

function EsimCard(props: EsimCardProps) {
  const { iccid, skin, status, label, onClick, Actions } = props;
  const { t } = useTranslation();
  const { censored, setCensored, censorName, censorIccid } = useEsimCensored();

  const { copy } = useClipboard();

  return (
    <div
      className={classNames(
        EsimCardStyles.container,
        onClick && EsimCardStyles.containerHover,
      )}
      onClick={onClick}
      style={{
        backgroundImage: `url(${AssetPaths.SimSkins}/${skin ?? SimSkinEnum.Dark}.png)`,
      }}
    >
      <div
        className={classNames(
          structuralStyles.flexbox({ justify: 'between' }),
          structuralStyles.fill({ width: true }),
        )}
      >
        <div
          className={structuralStyles.flexbox({
            wrap: false,
            fill: true,
            gap: 4,
          })}
        >
          <Text wrap={false} textColor="mainWhite" textVariant="h3">
            {censorName(label ?? '***** *****')}
          </Text>
          <ActionIcon
            variant="transparent"
            onClick={(e) => {
              setCensored((vis) => !vis);
              e.stopPropagation();
              e.preventDefault();
            }}
            color={colors.mainWhite}
          >
            {censored ? <EyeSlash /> : <Eye />}
          </ActionIcon>
        </div>
        {Actions}
      </div>
      <div>
        <div className={structuralStyles.flexbox()}>
          <Text textColor="mainWhite" textVariant="body1Regular">
            {t('sim:iccid_number').toUpperCase()}
          </Text>
          {iccid && (
            <ActionIcon
              onClick={(e) => {
                copy(iccid);
                e.preventDefault();
                e.stopPropagation();
              }}
              variant="transparent"
            >
              <CopyIcon size={16} color={colors.mainWhite} />
            </ActionIcon>
          )}
        </div>
        <Text textColor="mainWhite" className={EsimCardStyles.iccidTypography}>
          {censorIccid(iccid ?? 'XXXX XXXX XXXX XXXX XXX')}
        </Text>
      </div>
      <div
        className={classNames(
          structuralStyles.flexbox({ justify: 'between' }),
          structuralStyles.fill({ width: true }),
        )}
      >
        <Tooltip label={t('sim:global_tooltip')}>
          <div
            className={structuralStyles.flexbox({ gap: 4 })}
            onClick={interceptClickEvents}
          >
            <Text textColor="mainWhite">Global eSIM</Text>
            <Globe color={colors.mainWhite} />
          </div>
        </Tooltip>
        {status && <EsimCardStatusIndicator status={status} />}
      </div>
    </div>
  );
}

// +=====================+
// |       EXPORTS       |
// +=====================+

(EsimCard as any).ActionsButton = EsimActionsButton;
(EsimCard as any).StatusIndicator = EsimCardStatusIndicator;

export default EsimCard as typeof EsimCard & {
  ActionsButton: typeof EsimActionsButton;
  StatusIndicator: typeof EsimCardStatusIndicator;
};
