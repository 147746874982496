import { useUpdateLabelSim } from 'api-hooks/sim/mutation';
import notification from 'common/helpers/notification';
import Button from 'components/elements/button';
import TextInput from 'components/elements/text-input';
import BottomSheet, {
  BottomSheetRemote,
} from 'components/widgets/bottom-sheet';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  simId?: string;
}

const RenameAction = React.forwardRef<BottomSheetRemote, Props>(
  (props, ref) => {
    const { simId } = props;
    const { t } = useTranslation();
    const [name, setName] = React.useState('');

    const { mutateAsync, isLoading } = useUpdateLabelSim();

    const onSave = React.useCallback(async () => {
      try {
        const result = await mutateAsync({
          simId: simId!,
          body: {
            label: name,
          },
        });
        notification.success({ message: result?.message });
        (ref as any)?.current?.close();
      } catch (e: any) {
        console.error(e);
        if (e?.message) {
          notification.error({ message: e.message });
        }
      }
    }, [mutateAsync, name, ref, simId]);

    return (
      <BottomSheet
        ref={ref}
        title={t('sim:rename_your_esim')}
        defaultSnap={({ snapPoints }) => Math.min(...snapPoints)}
        footer={
          <Button fullWidth onClick={onSave} loading={isLoading}>
            {t('common:save')}
          </Button>
        }
      >
        <TextInput
          maxLength={15}
          label={t('sim:rename_input')}
          onChange={(e) => setName(e?.target?.value)}
        />
      </BottomSheet>
    );
  },
);

export default RenameAction;
