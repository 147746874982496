import { Scroll } from '@phosphor-icons/react';
import { SimModel } from 'api-hooks/sim/model';
import {
  useArchiveSim,
  useDeleteSim,
  useRestoreSim,
  useShareLinkSim,
} from 'api-hooks/sim/mutation';
import classNames from 'classnames';
import {
  ArchiveIcon,
  NotePencilIcon,
  PaletteIcon,
  PaperPlaneTiltIcon,
  ShareIcon,
  SimIcon,
  TrashSimpleIcon,
} from 'common/assets';
import RestoreIcon from 'common/assets/svg/restore';
import notification from 'common/helpers/notification';
import { CustomValue } from 'common/repositories/customization';
import { NavigationRoutes } from 'common/routes';
import colors from 'common/styles/colors';
import Text from 'components/elements/text';
import BottomSheet, {
  BottomSheetRemote,
} from 'components/widgets/bottom-sheet';
import useClipboard from 'hooks/use-clipboard';
import { useConfirmDeleteDialog } from 'hooks/use-dialog';
import useKurosimNavigation, {
  resolveDynamicRoute,
} from 'hooks/use-kurosim-navigation';
import useWindowLocationOrigin from 'hooks/use-window-location';
import { commonStyles } from 'modules/components/styles.css';
import LoadingViewComponent from 'pages/loading';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import structuralStyles from 'styles/layout.css';

import RenameAction from './action-component/rename';
import TransferAction from './action-component/transfer';
import { EsimActionStyles } from './styles.css';

interface Props {
  data: SimModel;
}

const ESimActions = React.forwardRef<BottomSheetRemote, Props>((props, ref) => {
  const { data } = props;
  const { t } = useTranslation();
  const { push, back } = useKurosimNavigation();
  const { copy } = useClipboard();
  const renameRef = React.useRef<BottomSheetRemote | null>(null);
  const transferRef = React.useRef<BottomSheetRemote | null>(null);

  const origin = useWindowLocationOrigin();

  const { mutateAsync: archiveSim, isLoading: archiveSimLoading } =
    useArchiveSim();
  const { mutateAsync: restoreSim, isLoading: restoreSimLoading } =
    useRestoreSim();
  const { mutateAsync: shareLinkSim, isLoading: shareLinkSimLoading } =
    useShareLinkSim();
  const { mutateAsync: deleteSim, isLoading: deleteSimLoading } =
    useDeleteSim();

  const onArchiveRestoreSim = React.useCallback(async () => {
    try {
      if (data?.archivedAt) {
        const result = await restoreSim({ simId: data?.id! });
        notification.success({ message: result?.message });
      } else {
        const result = await archiveSim({ simId: data?.id! });
        notification.success({ message: result?.message });
      }
    } catch (e: any) {
      console.error(e);
      notification.error({ message: e.message });
    }
  }, [archiveSim, data?.archivedAt, data?.id, restoreSim]);

  const onShare = React.useCallback(async () => {
    try {
      const result = await shareLinkSim({
        simId: data?.id!,
      });
      const publicLink = `${origin}${resolveDynamicRoute(
        NavigationRoutes.EsimPublic,
        {
          id: data?.id!,
        },
      )}?t=${result?.token}`;
      setTimeout(() => {
        copy(publicLink);
      }, 300);
    } catch (e: any) {
      console.error(e);
      notification.error({ message: e.message });
    }
  }, [copy, data?.id, origin, shareLinkSim]);

  const onDelete = React.useCallback(async () => {
    try {
      const result = await deleteSim({ simId: data?.id! });
      notification.success({ message: result?.message });
      back();
    } catch (e: any) {
      console.error(e);
      notification.error({ message: e.message });
    }
  }, [deleteSim, data?.id, back]);

  const { Dialog, open } = useConfirmDeleteDialog({
    type: 'confirmation',
    title: t('common:delete_extra', { extra: t('sim:esim') }),
    content: t('sim:delete_esim_content'),
    confirmationButtonProps: {
      loading: deleteSimLoading,
      onClick: () => onDelete(),
    },
  });

  const list = React.useMemo(
    () => [
      {
        icon: data?.archivedAt ? RestoreIcon : ArchiveIcon,
        label: data?.archivedAt ? t('sim:restore') : t('sim:archive'),
        onClick: () => onArchiveRestoreSim(),
      },
      {
        icon: NotePencilIcon,
        label: t('sim:rename'),
        onClick: () => renameRef?.current?.open(),
      },
      {
        icon: SimIcon,
        label: t('sim:install_esim'),
        onClick: () => {
          push(NavigationRoutes.EsimInstallGuide, {
            paths: {
              id: data.id,
            },
            query: {},
          });
        },
      },
      {
        icon: PaletteIcon,
        label: t('sim:customize_design'),
        onClick: () => {
          push(NavigationRoutes.CustomizeDesign, {
            paths: {
              id: data?.id,
            },
          });
        },
      },
      {
        icon: Scroll,
        label: t('sim:plan_history'),
        onClick: () => {
          push(NavigationRoutes.EsimHistory, {
            locker: {
              data,
            },
            paths: {
              id: data.id,
            },
          });
        },
      },
      {
        icon: ShareIcon,
        label: t('sim:share_public_link'),
        onClick: () => onShare(),
      },
      {
        icon: PaperPlaneTiltIcon,
        label: t('sim:transfer_to_account'),
        onClick: () => transferRef?.current?.open(),
      },
      {
        icon: TrashSimpleIcon,
        label: t('sim:delete_from_account'),
        color: colors.sentimentNegative,
        onClick: () => open(),
      },
    ],
    [data, onArchiveRestoreSim, onShare, open, push, t],
  );

  return (
    <>
      <BottomSheet
        ref={ref}
        title={t('sim:more')}
        customization={{
          body: {
            style: CustomValue.combine({ padding: 0 }),
          },
        }}
      >
        <div>
          {list?.map((item, idx) => (
            <React.Fragment key={idx}>
              <div
                onClick={() => {
                  (ref as any)?.current?.close();
                  item?.onClick();
                }}
                className={classNames(
                  commonStyles.hoverStyle,
                  structuralStyles.padding({ horizontal: 24 }),
                )}
              >
                <div
                  className={classNames(
                    EsimActionStyles.container,
                    structuralStyles.padding({ vertical: 12 }),
                    structuralStyles.flexbox({
                      direction: 'row',
                      justify: 'between',
                    }),
                  )}
                >
                  <Text c={item?.color} textVariant="body1Semibold">
                    {item?.label}
                  </Text>
                  <item.icon size={24} color={item?.color} />
                </div>
              </div>
            </React.Fragment>
          ))}
        </div>
      </BottomSheet>
      <RenameAction ref={renameRef} simId={data?.id} />
      <TransferAction ref={transferRef} simId={data?.id} />
      {Dialog}
      {(archiveSimLoading || restoreSimLoading || shareLinkSimLoading) && (
        <LoadingViewComponent />
      )}
    </>
  );
});

export default ESimActions;
