function RestoreIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        fill="#000"
        fillRule="evenodd"
        d="M4.58 3.415A.75.75 0 015.25 3h13.5a.75.75 0 01.67.415l1.5 3a.75.75 0 01.08.335V19.5a1.5 1.5 0 01-1.5 1.5h-15A1.5 1.5 0 013 19.5V6.75a.75.75 0 01.08-.335l1.5-3zM5.713 4.5L4.5 6.927V19.5h15V6.927L18.287 4.5H5.713z"
        clipRule="evenodd"
      />
      <path
        fill="#000"
        fillRule="evenodd"
        d="M3 6.75A.75.75 0 013.75 6h16.5a.75.75 0 010 1.5H3.75A.75.75 0 013 6.75zM12 9a.75.75 0 01.75.75v7.5a.75.75 0 01-1.5 0v-7.5A.75.75 0 0112 9z"
        clipRule="evenodd"
      />
      <path
        fill="#000"
        fillRule="evenodd"
        d="M8.47 13.28a.75.75 0 001.06 0L12 10.81l2.47 2.47a.75.75 0 101.06-1.06l-3-3a.75.75 0 00-1.06 0l-3 3a.75.75 0 000 1.06z"
        clipRule="evenodd"
      />
    </svg>
  );
}

export default RestoreIcon;
