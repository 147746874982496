import { PrefixUrl } from 'common/api/prefix-url';
import React from 'react';

import useDetectDevice from './use-detect-device';

export default function useWindowLocationOrigin() {
  const { isKurosimApp } = useDetectDevice();
  return React.useMemo(() => {
    if (typeof window === 'undefined') return '';
    // because isKurosimApp is run by static server

    if (isKurosimApp) {
      if (PrefixUrl.isStaging()) {
        return 'https://staging-app.kurosim.com';
      }

      return 'https://app.kurosim.com';
    }

    return window.location.origin;
  }, [isKurosimApp]);
}
