import Text from 'components/elements/text';
import TextInput from 'components/elements/text-input';
import Alert from 'components/widgets/alert';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import structuralStyles from 'styles/layout.css';
import { useDebouncedCallback } from 'use-debounce';

interface Props {
  setEmail: React.Dispatch<React.SetStateAction<string>>;
}

export default function TransferEmail(props: Props) {
  const { setEmail } = props;
  const { t } = useTranslation();

  const debounced = useDebouncedCallback((value) => setEmail(value), 800);

  return (
    <div
      className={structuralStyles.flexbox({
        direction: 'column',
        align: 'stretch',
        gap: 16,
      })}
    >
      <Text>
        {t('sim:transfer_email_desc')}
        <br />
        <Text
          inline
          style={{
            fontStyle: 'italic',
          }}
        >
          {t('sim:send_by_email_note_description')}
        </Text>
      </Text>
      <Alert alertVariant="warn">{t('sim:transfer_account_desc')}</Alert>
      <TextInput
        placeholder={t('common:email_placeholder')}
        onChange={(e) => debounced(e?.target?.value)}
      />
    </div>
  );
}
