import { SegmentedControl } from '@mantine/core';
import { useShareLinkSim, useTransferSim } from 'api-hooks/sim/mutation';
import classNames from 'classnames';
import notification from 'common/helpers/notification';
import { NavigationRoutes } from 'common/routes';
import Button from 'components/elements/button';
import BottomSheet, {
  BottomSheetRemote,
} from 'components/widgets/bottom-sheet';
import useClipboard from 'hooks/use-clipboard';
import useKurosimNavigation, {
  resolveDynamicRoute,
} from 'hooks/use-kurosim-navigation';
import useWindowLocationOrigin from 'hooks/use-window-location';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import structuralStyles from 'styles/layout.css';

import TransferEmail from './transfer-email';
import TransferLink from './transfer-link';

enum TabEnum {
  Email = 'sim:send_by_email',
  Link = 'sim:share_link',
}

const segmentedData = (t) => [
  {
    label: t(TabEnum.Email),
    value: TabEnum.Email,
  },
  {
    label: t(TabEnum.Link),
    value: TabEnum.Link,
  },
];

interface Props {
  simId: string;
}

const TransferAction = React.forwardRef<BottomSheetRemote, Props>(
  (props, ref) => {
    const { simId } = props;
    const { t } = useTranslation();
    const [tab, setTab] = React.useState(TabEnum.Email);
    const [email, setEmail] = React.useState('');
    const { copy } = useClipboard();
    const { push } = useKurosimNavigation();

    const { mutateAsync: transferSim, isLoading: transferSimLoading } =
      useTransferSim();
    const {
      data,
      mutateAsync: shareLinkSim,
      isLoading: shareLinkSimLoading,
    } = useShareLinkSim();

    const origin = useWindowLocationOrigin();

    const link = `${origin}${resolveDynamicRoute(NavigationRoutes.EsimPublic, {
      id: simId,
    })}?t=${data?.token}`;

    const onClick = React.useCallback(async () => {
      if (tab === TabEnum.Email) {
        try {
          const result = await transferSim({
            simId: simId!,
            body: {
              email,
            },
          });
          notification.success({ message: result?.message });
          push(NavigationRoutes.MySim);
          (ref as any)?.current?.close();
        } catch (e: any) {
          console.error(e);
          notification.error({ message: e.message });
        }
      } else {
        if (navigator.clipboard) {
          await copy(link);
          (ref as any)?.current?.close();
        }
      }
    }, [copy, email, link, push, ref, simId, tab, transferSim]);

    return (
      <BottomSheet
        ref={ref}
        defaultSnap={({ lastSnap, snapPoints }) =>
          lastSnap ?? Math.max(...snapPoints)
        }
        title={t('sim:transfer_to_account')}
        footer={
          <Button
            fullWidth
            loading={transferSimLoading || shareLinkSimLoading}
            onClick={onClick}
          >
            {tab === TabEnum.Email
              ? `${t('common:send')} ${t('common:email')}`
              : t('sim:share_link')}
          </Button>
        }
      >
        <SegmentedControl
          data={segmentedData(t)}
          value={tab}
          onChange={setTab as any}
          classNames={{
            root: classNames(
              structuralStyles.fill({ width: true }),
              structuralStyles.margin({ bottom: 16 }),
            ),
          }}
        />
        {tab === TabEnum.Email ? (
          <TransferEmail setEmail={setEmail} />
        ) : (
          <TransferLink simId={simId} link={link} mutateAsync={shareLinkSim} />
        )}
      </BottomSheet>
    );
  },
);

export default TransferAction;
